import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import { DiJavascript1, DiReact, DiNodejs, DiMongodb, DiPython, DiGit, DiJava } from "react-icons/di";
import { FaPhp, FaLaravel, FaAngular, FaVuejs, FaDocker, FaAws } from "react-icons/fa";
import { SiRedis, SiFirebase, SiNextdotjs, SiPostgresql, SiMysql, SiSymfony, SiCakephp, SiNestjs, SiDjango, SiFlask, SiRubyonrails, SiSpringboot, SiMongodb as SiMongodbAlt, SiKubernetes, SiTerraform, SiAnsible, SiChef, SiPuppet, SiJenkins, SiGithubactions, SiGitlab, SiCircleci, SiPrometheus, SiGrafana, SiElasticsearch, SiRabbitmq, SiAmazonaws, SiCypress, SiMocha, SiJest, SiSelenium } from "react-icons/si";
import { DiRuby } from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <h1 className="project-heading">
        <strong className="purple"> Programming </strong> Languages
      </h1>
      <Col xs={4} md={2} className="tech-icons"><CgCPlusPlus /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiJavascript1 /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiNodejs /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiReact /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiMongodb /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiNextdotjs /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiGit /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiFirebase /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiRedis /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiPostgresql /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiPython /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiJava /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaPhp /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaLaravel /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaAngular /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaVuejs /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiSymfony /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiCakephp /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiNestjs /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiDjango /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiFlask /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiRubyonrails /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiSpringboot /></Col>
      <Col xs={4} md={2} className="tech-icons"><DiRuby /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiMysql /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiMongodbAlt /></Col>
      <h1 className="project-heading">
        <strong className="purple"> DevOps </strong> & Cloud
      </h1>
      <Col xs={4} md={2} className="tech-icons"><SiElasticsearch /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiRabbitmq /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaDocker /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiKubernetes /></Col>
      <Col xs={4} md={2} className="tech-icons"><FaAws /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiAmazonaws /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiTerraform /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiAnsible /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiChef /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiPuppet /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiJenkins /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiGithubactions /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiGitlab /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiCircleci /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiPrometheus /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiGrafana /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiCypress /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiMocha /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiJest /></Col>
      <Col xs={4} md={2} className="tech-icons"><SiSelenium /></Col>
    </Row>
  );
}

export default Techstack;
