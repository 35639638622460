import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              My journey into software development began as a spark of curiosity, and over the years, that spark has grown into a fervent passion. With each new project, I’ve immersed myself deeper into understanding not just how to write code, but how to architect robust, resilient, and high-performing systems. Today, I view myself not just as a developer, but as a solution architect—someone who thrives on conceptualizing software solutions that are scalable, maintainable, and primed for evolution.
            </p>
            
            <p className="home-about-body">
              At the core of my expertise lies a profound understanding of 
              <i>
                <b className="purple">PHP</b>
              </i> and 
              <i>
                <b className="purple">Node.js</b>
              </i>. These technologies form the backbone of many of the solutions I create. With PHP, I leverage frameworks such as 
              <i>
                <b className="purple"> Laravel, CakePHP, Symfony, and YII </b>
              </i> to develop robust server-side architectures. Meanwhile, Node.js empowers me to build scalable, event-driven back-ends that handle high traffic and ensure seamless responsiveness.
            </p>

            <p className="home-about-body">
              My work extends far beyond simply coding features. I consider the entire lifecycle of a product—envisioning data flows, designing API endpoints, setting up security layers, and ensuring that the architecture can effortlessly scale as user demands increase. By combining best practices in modular design, microservices, and API-driven development, I create software ecosystems that are adaptable and ready for any challenge the future may bring.
            </p>

            <p className="home-about-body">
              On the front-end, I draw upon the power of 
              <i>
                <b className="purple"> React.js, Next.js, Angular.js, and Vue.js </b>
              </i> to deliver intuitive, high-performance user interfaces. This user-centric approach ensures that the complexity beneath the surface translates into a smooth and engaging experience for the end user.
            </p>

            <p className="home-about-body">
              On the infrastructure side, I integrate principles of 
              <i>
                <b className="purple"> DevOps, Cloud Hosting, CI/CD </b>
              </i>, and containerization using 
              <i>
                <b className="purple"> Docker, EC2, ECS, and ECR </b>
              </i>. This not only guarantees a streamlined deployment pipeline but also lays the foundation for systems that can dynamically respond to load, recover gracefully from failures, and be managed with minimal downtime. Pairing these capabilities with AWS services like 
              <i>
                <b className="purple"> IoT Core, Kinesis Video Streams, SQS, and Lambda </b>
              </i> allows me to build distributed, event-driven architectures that handle real-time data and complex workflows with ease.
            </p>

            <p className="home-about-body">
              Ultimately, my work revolves around transforming ideas into scalable realities. Whether starting from a blank slate or refining an existing system, I approach every project as an opportunity to refine architectures, embrace new patterns, and craft solutions that are both elegant and enduring. Every decision I make—technology selection, structural design, integration approach—is guided by a commitment to longevity, scalability, and the unwavering pursuit of technical excellence.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect</span> with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Bilal-Rehman"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/bilal-rehman-/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
